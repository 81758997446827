import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import FormInput from "../../components/form-input/form-input.component";
import CustomButton from "../../components/custom-button/custom-button.component";

import './sign-up.styles.scss'

const SignUp = (props) => {

    const [displayName, setDisplayName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // router
    const navigate = useNavigate();

    const handleSubmit = async(event) => {
        event.preventDefault();
        const toastId = toast.loading("Authenticating...");
        /*As razopray requires minimum name length 3 so marked a check */
        if(displayName.length < 3)
            return toast.update(toastId, { render: "Name should have atleast 3 characters", type: "error", isLoading: false, autoClose: 2000, delay: 100 });
        if(!email.endsWith('@supportelders.com'))
            return toast.update(toastId, { render: "The Email ID has invalid domain.", type: "error", isLoading: false, autoClose: 2000, delay: 100 });
        try {
            setIsLoading(true);
            await axios.post('/app-api/user/register', {displayName, email, password});
            toast.update(toastId, { render: 'Account created. Please sign in.', type: "success", isLoading: false, autoClose: 2000, delay: 100 });
            setIsLoading(false);
            // redirect
            navigate('/signin');
        } catch (err) {
            console.log(err);
            toast.update(toastId, { render: `${err.response?.data?.errorMessage || 'Something went wrong.'}`, type: "error", isLoading: false, autoClose: 2000, delay: 100 });
            setIsLoading(false);
        }
    }

    return(
        <div className="signup-page-background">
            <div className="signup-page-content">
                <h2 className="title">Register with Email and Password</h2>
                <form onSubmit={handleSubmit}>
                    <FormInput type="text" handleChange={ e => setDisplayName(e.target.value.trim()) } value={displayName} label="Name" autoComplete="off" required/>
                    <FormInput type="email" handleChange={ e => setEmail(e.target.value.trim()) } value={email} label="Email" required/>
                    <FormInput type="password" handleChange={ e => setPassword(e.target.value) } value={password} label="Password" required/>
                    <CustomButton type="submit" id="sign-up-button">Sign Up</CustomButton>
                </form>
                <div className="supporting-links-container">
                    <h5>By signing up you agree to our <a href="#/">Terms & Conditions</a> and <a href="#/">Privacy Policy</a></h5>
                </div>
                <hr />
                <div id="content-footer">
                    <h2 className="footer-header">Already have an account?</h2>
                    <CustomButton inverted type="button" id="footer-button" onClick={()=> navigate(`/signin`)}>Sign In</CustomButton>
                </div>
                {isLoading &&
                    <div className="disable-form" onClick={e => e.stopPropagation()}></div>
                }
            </div>
        </div>
    )
}

export default SignUp;