import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import { Context } from "../../context/context";
import CustomButton from "../../components/custom-button/custom-button.component";

import "./device-settings.styles.scss";

/*  Write Css */
function DeviceSettings() {
  const { deviceId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [patientID, setPatientID] = useState("");
  const [selectedTimezone, setSelectedTimezone] = useState({});

  /*Context data */
  const { state, dispatch } = useContext(Context);

  useEffect(() => {
    getSettingsData();
  }, []);

  const getSettingsData = async() => {
    setIsLoading(true);
    try {
      const {data} = await axios.get(`/app-api/device/${deviceId}/settings`);
      const {
        // caregiver,
        patientID,
        // timezoneOffset,
        timezoneValue
      } = data;
      setPatientID(patientID);
      setSelectedTimezone(timezoneValue);
    } catch (err) {
      // toast.error
      console.error(err);
      toast.error("Failed to load data");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async() => {
    if(!isLoading){
      try {
        setIsLoading(true);
        await axios.post(`/app-api/device/${deviceId}/settings`, {
          patientID,
        });
        toast.success("Settings updated!");
      } catch (err) {
        console.error(err);
        toast.error("Something went wrong");
      } finally {
        setIsLoading(false);
      }
    }
  }

  return (
    <div className="settings-page">
      <div className="form-group">
          <div className="label">
              <div className="text">Patient ID</div>
          </div>
          <input className={`input-field input-name ${(false) ? 'empty' : ''}`} type="text" placeholder="Enter Patient ID" value={patientID} onChange={(e) => {setPatientID(e.target.value)}} />
      </div>
      <div className="form-group">
          <div className="label">
              <div className="text">Device Timezone</div>
          </div>
          <input className={`input-field`} type="text" placeholder="Select Timezone" value={selectedTimezone} disabled/>
      </div>
      <div className="button-row">
        <CustomButton onClick={handleSave}>{isLoading ? <div className="spinner"></div> : 'SAVE'}</CustomButton>
      </div>
    </div>
  );
}

export default DeviceSettings;
