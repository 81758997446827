import axios from "axios";
import { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { toast } from "react-toastify";

import { Context } from "../../context/context";
import FormInput from "../../components/form-input/form-input.component";

import {ReactComponent as PlusIcon} from '../../assets/Plus_Icon.svg';

import './devices.styles.scss';
import { DateTime } from "luxon";

const Devices = (props) => {
    // state
    const [devices, setDevices] = useState([]);
    const [showAddNewDeviceModal, setShowAddNewDeviceModal] = useState(false);
    const [newDeviceID, setNewDeviceID] = useState('');
    const [newPatientID, setNewPatientID] = useState('');

    // router
    const navigate = useNavigate();

    useEffect( async() => {
        await loadMyDevices();
    }, [])

    const loadMyDevices = async() => {
        const {data} = await axios.post('/app-api/getDeviceList');
        if(data) setDevices(data.devices);
    }

    const handleAddNewDevice = async() => {
        try {
            if(!newDeviceID)
                return toast.error('Please Enter a Device ID');
            if(!newPatientID)
                return toast.error('Please Enter a Patient ID');
            
            const {data} = await axios.post('/app-api/add-device',{
                deviceId: newDeviceID,
                patientID: newPatientID,
            });
            const updatedMyDevices = [...devices, {
                id: newDeviceID,
                patientID: newPatientID,
                hasRecentMissedPill: false,
                sync:{
                    status: true,
                    syncedTime: null,
                    updatedTime: null,
                },
            }];
            setShowAddNewDeviceModal(false);
            setNewDeviceID('');
            setNewPatientID('');
            setDevices(updatedMyDevices);

            toast.success('Device Added');
        } catch (err) {
            console.log(err);
            toast.error(err?.response?.data?.errorMessage || 'Something went wrong');
        }
    }

    return(
        <div className="devices">
            {(!devices) ?
                <div></div> :
                <>
                    {/* <pre>{JSON.stringify(invites, null, 4)}</pre> */}
                    <div className="devices-header">
                        <h1>Device List</h1>
                        <span className="devices-header-refresh-time">Last Refreshed : {DateTime.now().toFormat('HH:MM')}</span>
                    </div>
                    <div className="devices-container">
                        <div className="device-card centered" key={0} onClick={ () => setShowAddNewDeviceModal(true) }>
                            <PlusIcon className = "image"/>
                            <h4>Add New Device</h4>
                        </div>
                        {devices.map( ({id, patientID, sync, hasRecentMissedPill}) => {
                            return(
                                <div className="device-card" key={id} onClick={ () => navigate(`/device/${id}/dashboard`) }>
                                    <h4>{patientID ? patientID : ''}</h4>
                                    {id}
                                    <div className="card-info-icon-container">
                                        {(hasRecentMissedPill) &&
                                            <div className="card-info-icon red">
                                                <span className="material-icons-outlined">notification_important</span>
                                            </div>
                                        }
                                        {(sync.status) ?
                                            <div className="card-info-icon green">
                                                <span className="material-icons-outlined">sync</span>
                                            </div>
                                            :
                                            <div className="card-info-icon red">
                                                <span className="material-icons-outlined">sync_problem</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                        
                    </div>
                </>
            }
            <Modal
                isOpen={showAddNewDeviceModal}
                onRequestClose={ () => setShowAddNewDeviceModal(false) }
                overlayClassName="modal-overlay"
                className="modal-content"
                closeTimeoutMS={200}
            >
                <div className="add-new-device-modal-content">
                    <h4>Add New Device</h4>
                    <hr/>
                    <div className="add-new-device-modal-body">
                        <div className="device-id-form">
                            <FormInput type="text" handleChange={ e => setNewDeviceID(e.target.value) } value={newDeviceID} label="Device ID"/>
                            <div className="vertical-seperator"></div>
                            <div><span className="material-icons-outlined" onClick={() => toast.info('QR scan comming soon!')}>qr_code_scanner</span></div>
                        </div>
                        <FormInput type="text" handleChange={ e => setNewPatientID(e.target.value) } value={newPatientID} label="Patient ID"/>
                    </div>
                    <hr/>
                    <div className="add-new-device-modal-footer">
                        <button className="button-cancel" onClick={() => setShowAddNewDeviceModal(false)}><h4>Cancel</h4></button>
                        <button className="button-add" onClick={handleAddNewDevice}><h4>Add</h4></button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Devices;