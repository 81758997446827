import { useState } from "react";
import { useNavigate } from "react-router-dom";

import ProfileGeneral from "../../components/profile-general/profile-general.component";
// import ProfileImage from "../../components/profile-image/profile-image.component";
// import ProfileCloseAccount from "../../components/profile-close-account/profile-close-account.component";

import "./profile.styles.scss";

const Profile = (props) => {

    const tabs = Object.freeze([
        // "View Public Profile",
        "General", 
        // "Photo",
        // "Account",
        // "Preferences",
        // "Close Account",
    ]);

    const [currentTab, setCurrentTab] = useState(0);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    // router
    const navigate = useNavigate();

    return (
        <div className="profile">
            <div className="profile-header">
                <button onClick={() => navigate(-1)}><span className="material-icons-outlined">arrow_back</span><span className="button-text">Go Back</span></button>
                <span className="tab-heading">Profile</span>
            </div>
            <div className="profile-layout">
                {/* Tabs side panel for larger screens */}
                <div className="profile-side-tabs">
                    {
                        tabs.map( (tab, index) => 
                            <div className={`tab ${index===currentTab ? 'highlighted' : ''}`} key={index} onClick={() => setCurrentTab(index)}>{tab}</div> 
                        )
                    }
                </div>
                {/* Tabs dropdown for smaller screens */}
                <div className="profile-dropdown-tabs">
                    <div className="profile-dropdown-tabs-header">
                        <span className="current-tab">{tabs[currentTab]}</span>
                        <button onClick={() => setIsDropdownOpen(!isDropdownOpen)}><span className="material-icons-outlined">arrow_drop_down</span></button>
                    </div>
                    {(isDropdownOpen) &&
                        <div className="profile-dropdown-tabs-container">
                            {
                                tabs.map( (tab, index) => (
                                    index===currentTab ?
                                        <></>
                                    :
                                        <div
                                            className="tab"
                                            key={index}
                                            onClick={ () => {
                                                setIsDropdownOpen(false);
                                                setCurrentTab(index);
                                            }}
                                        >
                                                {tab}
                                        </div> 
                                ))
                            }
                        </div>
                    }
                </div>
                <div className="profile-content">
                    {
                        currentTab === 0 ?
                            <ProfileGeneral />
                        : currentTab === 1 ?
                            <div></div>
                            // <ProfileCloseAccount />
                        : currentTab === 2 ?
                            <div></div>
                            // <ProfileImage />
                        : currentTab === 3 ?
                            <div></div>
                        :
                            <div></div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Profile;