import { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import { Context } from "../../context/context";

import CustomButton from "../custom-button/custom-button.component";

import "./profile-general.styles.scss";

const ProfileGeneral = ( props ) => {
    // state
    const [isLoading, setIsLoading] = useState(false);
    const [displayName, setDisplayName] = useState("");
    const [gender, setGender] = useState(null);
    const [dob, setDob] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);

    // state
    const { state, dispatch } = useContext(Context);
    const { user } = state;

    useEffect( () => {
        setIsLoading(true);
        
        setDisplayName(user.displayName);
        setGender(user.gender);
        setDob(user.dob ? DateTime.fromISO(user.dob).setZone("local", {keepLocalTime: true}).toJSDate() : null);
        setPhoneNumber(user.phoneNumber);
        
        setIsLoading(false);
    }, [user]);

    const handleSave = async() => {
        if(!isLoading){
            try {
                setIsLoading(true);
                if(!displayName) throw { validationError: "Name is required"};
    
                const payload = {
                    displayName: displayName,
                    gender: gender ? gender : null,
                    dob: dob ? DateTime.fromJSDate(dob).set({ hour: 0, minute: 0, second: 0 }).setZone("utc", {keepLocalTime: true}).toISO() : null,
                    phoneNumber: phoneNumber ? phoneNumber : null,
                };
    
                if(
                    payload.displayName === user.displayName &&
                    payload.gender === user.gender &&
                    payload.dob === user.dob &&
                    payload.phoneNumber === user.phoneNumber
                )   throw { validationError: "No changes made"};
    
                const { data } = await axios.post(
                    `/app-api/user/update-profile`,
                    payload
                );
                dispatch({
                    type: "UPDATE_PROFILE",
                    payload: payload,
                });
                toast.success("Profile details updated");
            } catch (err) {
                console.log(err);
                toast.error(err.validationError ? err.validationError : "Something went wrong");
            } finally {
                setIsLoading(false);
            }
        }
    }
    
    return(
        <div className="profile-general">
            <div className="form-group">
                <div className="label">
                    <div className="text">Name</div>
                    <div className="visibility">
                        {
                            (!displayName || displayName.length===0) &&
                            <span className="material-icons-outlined">report_problem</span>
                        }
                        <span className="material-icons-outlined">public</span>
                    </div>
                </div>
                <input className={`input-field input-name ${(!displayName || displayName.length===0) ? 'empty' : ''}`} type="text" placeholder="Enter your name" value={displayName} onChange={(e) => setDisplayName(e.target.value)} />
            </div>
            <div className="form-group">
                <div className="label">
                    <div className="text">Gender</div>
                    <div className="visibility"><span className="material-icons-outlined">lock</span></div>
                </div>
                <div className="input-field input-gender">
                    <label className="radio-label-container">
                        <input type="radio" name="gender" onChange={(e) => setGender(e.target.value)} value="Male" checked={gender==="Male"} />
                        Male
                    </label>
                    <label className="radio-label-container">
                        <input type="radio" name="gender" onChange={(e) => setGender(e.target.value)} value="Female" checked={gender==="Female"} />
                        Female
                    </label>
                    <label className="radio-label-container">
                        <input type="radio" name="gender" onChange={(e) => setGender(e.target.value)} value="Others" checked={gender==="Others"} />
                        Others
                    </label>
                </div>
            </div>
            <div className="form-group">
                <div className="label">
                    <div className="text">Date of Birth</div>
                    <div className="visibility"><span className="material-icons-outlined">lock</span></div>
                </div>
                <DatePicker
                    className="input-field input-dob"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/YYYY"
                    minDate={new Date("01/01/1900")}
                    maxDate={new Date()}
                    openToDate={dob ? undefined : new Date("01/01/2000")}
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    showPopperArrow={false}
                    selected={dob}
                    onChange={(date) => setDob(date)}
                />
            </div>
            <div className="form-group">
                <div className="label">
                    <div className="text">Mobile Number</div>
                    <div className="visibility"><span className="material-icons-outlined">lock</span></div>
                </div>
                <PhoneInput
                    containerClass="input-field input-phone"
                    preferredCountries={['in']}
                    placeholder="Enter your mobile number"
                    value={phoneNumber}
                    onChange={phone => setPhoneNumber(phone)}
                />
            </div>
            <div className="button-row">
                <CustomButton onClick={handleSave}>{isLoading ? <div className="spinner"></div> : 'SAVE'}</CustomButton>
            </div>
        </div>
    )
}

export default ProfileGeneral;