import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { DateTime, Info } from 'luxon';

import BoxEditForm from '../../components/box-edit-form/box-edit-form.component';
import { getTimeStringfromAlarmCode } from '../../utilities/utils';

import './schedule.styles.scss';

const Schedule = (props) => {
    // state
    const [deviceInfo , setDeviceInfo] = useState(null);
    const [isTimezoneDiff, setIsTimezoneDiff] = useState(false);
    const [editModalBoxNumber, setEditModalBoxNumber] = useState(0);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    // router
    const { deviceId } = useParams();

    useEffect( () => {
        loadDeviceInfo();
    }, []);

    const loadDeviceInfo = async() => {
        const {data} = await axios.get(`/app-api/device/${deviceId}/schedule`);
        if(data.deviceInfo) setDeviceInfo(data.deviceInfo);
        if(data.deviceTimezone){
            if(Info.normalizeZone(data.deviceTimezone.timezoneValue).offset(0) !== DateTime.now().offset)
                setIsTimezoneDiff(true);
        }
    }

    const handleBoxDataSave = async(boxIndex, medicineName, quantity, dose, alarms) => {
        try {
            const {data} = await axios.post(`/app-api/device/${deviceId}/box`, {
                alarms,
                dose,
                quantity,
                medicineName,
                box: boxIndex
            });
            const updatedDeviceInfo = [...deviceInfo];
            updatedDeviceInfo[boxIndex-1] = {
                medicineName,
                quantity,
                dose,
                alarms,
            };
            setDeviceInfo(updatedDeviceInfo);
            setIsEditModalOpen(false);
            toast.success(`Box${boxIndex} updated!`);
        } catch (err) {
            console.log(err);
            toast.error(err.response.data);
        }
    }

    return(
        <div className="schedule">
            {   
                (deviceInfo != null)
                ?
                    <>
                        {(isTimezoneDiff) &&    
                            <div className="timezone-info">
                                <span className="material-icons-outlined">warning_amber</span>
                                <div className="timezone-info-text">
                                    <h4>You seem to be in a different timezone than this device!</h4>
                                    <h6 className="timezone-info-text-sub">Alarm times shown below are in this MedpeR's local timezone.</h6>
                                </div>
                            </div>
                        }
                        <div className="card-list">
                            {deviceInfo.map( (boxInfo, index) => 
                                <div className="card" key={index}>
                                    <div className="card-header">
                                        <div className="card-info-head">{ boxInfo.medicineName }</div>
                                    </div>
                                    {
                                        (boxInfo.medicineName) ?
                                        <>
                                            <div className="card-info">
                                                Pill Quantity : <div className="card-info-value">{ boxInfo.quantity }</div>
                                            </div>
                                            <div className="card-info">
                                                Pill Dosage : <div className="card-info-value">{ boxInfo.dose }</div>
                                            </div>
                                            <div className="card-alarms">
                                                {boxInfo.alarms.map( (alarmCode, index) => {
                                                    if(alarmCode > 0 )
                                                        return(
                                                            <div key={index} className="card-alarm-time">
                                                                {getTimeStringfromAlarmCode(alarmCode)}
                                                            </div>
                                                        )
                                                    else
                                                        return(<div key={index} style={{display: "none"}}/>)
                                                })}
                                                {
                                                    boxInfo.alarms.every(thing => thing <= 0) && 
                                                    <div className="card-alarm-time">
                                                        No active alarms
                                                    </div>
                                                }
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="card-empty">
                                                <h4 className="card-empty-message">Pill box empty</h4>
                                                <button 
                                                    className="button-blue" 
                                                    onClick={() => {
                                                        setIsEditModalOpen(true);
                                                        setEditModalBoxNumber(index+1);
                                                    }} 
                                                >
                                                    <h4>Add Alarms</h4>
                                                </button>
                                            </div>
                                        </>
                                    }
                                    <div className="card-footer">
                                        <div className="box-name-wrapper">
                                            { `Box ${index + 1}` }
                                        </div>
                                        <button 
                                            className="card-edit-button" 
                                            onClick={() => {
                                                setIsEditModalOpen(true);
                                                setEditModalBoxNumber(index+1);
                                            }} 
                                        >
                                            <span className="material-icons-outlined">edit</span><h4>EDIT</h4>
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <Modal
                            isOpen={isEditModalOpen && (!!editModalBoxNumber)}
                            onRequestClose={ () => setIsEditModalOpen(false) }
                            overlayClassName="modal-overlay"
                            className="modal-content"
                            closeTimeoutMS={200}
                            onAfterClose={ () => setEditModalBoxNumber(0) }
                        >
                            <BoxEditForm 
                                boxNumber={editModalBoxNumber}
                                setIsEditModalOpen={setIsEditModalOpen}
                                boxInfo={deviceInfo[editModalBoxNumber-1]}
                                handleBoxDataSave={handleBoxDataSave}
                            />
                        </Modal>
                    </>
                :
                    <div>Loading...</div>
            }
        </div>
    )
}

export default Schedule;