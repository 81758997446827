import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';

import { getNotificationDateString, areEqualDates, getTimeString } from '../../utilities/utils';

import './notifications.styles.scss';

const Notifications = (props) => {

    // state
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [deviceTimezone, setDeviceTimezone] = useState(null);
    const [nextPageInfo, setnextPageInfo] = useState({
        token: null,
        filter: null
    });
    const [displayedNotifications, setDisplayedNotifications] = useState([]);
    const [isSortModalOpen, setIsSortModalOpen] = useState(false);
    const [isSortDesc, setIsSortDesc] = useState(true);
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const [showTaken, setShowTaken] = useState(true);
    const [showMissed, setShowMissed] = useState(true);
    const [showWrong, setShowWrong] = useState(true);
    const [showLowPills, setShowLowPills] = useState(true);
    const [showNoPills, setShowNoPills] = useState(true);
    const [showOthers, setShowOthers] = useState(true);
    const [showBox1, setShowBox1] = useState(true);
    const [showBox2, setShowBox2] = useState(true);
    const [showBox3, setShowBox3] = useState(true);
    const [showBox4, setShowBox4] = useState(true);
    const [showBox5, setShowBox5] = useState(true);
    const [showBox6, setShowBox6] = useState(true);
    const [showBox7, setShowBox7] = useState(true);
    const [showBox8, setShowBox8] = useState(true);

    // refs
    const sortSelectRef = useRef(null);
    const filterTakenRef = useRef(null);
    const filterMissedRef = useRef(null);
    const filterWrongRef = useRef(null);
    const filterLowPillsRef = useRef(null);
    const filterNoPillsRef = useRef(null);
    const filterOthersRef = useRef(null);
    const filterBox1Ref = useRef(null);
    const filterBox2Ref = useRef(null);
    const filterBox3Ref = useRef(null);
    const filterBox4Ref = useRef(null);
    const filterBox5Ref = useRef(null);
    const filterBox6Ref = useRef(null);
    const filterBox7Ref = useRef(null);
    const filterBox8Ref = useRef(null);

    // router
    const { deviceId } = useParams();

    useEffect( () => {
        getNotifications();
    }, [])

    useEffect( () => {
        let sortedNotifications = [...notifications];
        if(!showTaken)
            sortedNotifications = sortedNotifications.filter( notification => notification.code[0] !== "7" );
        if(!showMissed)
            sortedNotifications = sortedNotifications.filter( notification => notification.code[0] !== "6" );
        if(!showWrong)
            sortedNotifications = sortedNotifications.filter( notification => notification.code[0] !== "1" );
        if(!showLowPills)
            sortedNotifications = sortedNotifications.filter( notification => notification.code[0] !== "3" );
        if(!showNoPills)
            sortedNotifications = sortedNotifications.filter( notification => notification.code[0] !== "2" );
        if(!showOthers)
            sortedNotifications = sortedNotifications.filter( notification => 
                !(
                    notification.code[0] !== "7" &&
                    notification.code[0] !== "6" &&
                    notification.code[0] !== "1" &&
                    notification.code[0] !== "3" &&
                    notification.code[0] !== "2"
                )
            );
        if(!showBox1)
            sortedNotifications = sortedNotifications.filter( notification => notification.code[2] !== "1" );
        if(!showBox2)
            sortedNotifications = sortedNotifications.filter( notification => notification.code[2] !== "2" );
        if(!showBox3)
            sortedNotifications = sortedNotifications.filter( notification => notification.code[2] !== "3" );
        if(!showBox4)
            sortedNotifications = sortedNotifications.filter( notification => notification.code[2] !== "4" );
        if(!showBox5)
            sortedNotifications = sortedNotifications.filter( notification => notification.code[2] !== "5" );
        if(!showBox6)
            sortedNotifications = sortedNotifications.filter( notification => notification.code[2] !== "6" );
        if(!showBox7)
            sortedNotifications = sortedNotifications.filter( notification => notification.code[2] !== "7" );
        if(!showBox8)
            sortedNotifications = sortedNotifications.filter( notification => notification.code[2] !== "8" );
        if(isSortDesc)
            sortedNotifications.sort((a,b) => new Date(b.timestamp) - new Date(a.timestamp));  //desc
        else
            sortedNotifications.sort((a,b) => new Date(a.timestamp) - new Date(b.timestamp));  //asce
        setDisplayedNotifications( sortedNotifications );
    }, [notifications, isSortDesc, showTaken, showMissed, showWrong, showLowPills, showNoPills, showOthers, showBox1, showBox2, showBox3, showBox4, showBox5, showBox6, showBox7, showBox8]);

    const getNotifications = async() => {
        setIsLoading(true);
        try {
            const {data} = await axios.get(`/app-api/device/${deviceId}/notification${ nextPageInfo.token ? `?token=${nextPageInfo.token}&filter=${nextPageInfo.filter}` : ''}`);
            if(data){
                const updatedNotifications = [...notifications];
                updatedNotifications.push(...data.notifications);
                setNotifications(updatedNotifications);

                setDeviceTimezone(data.deviceTimezone);

                const updatedNextPageInfo = {
                    token: data.nextPageInfo.nextPageToken,
                    filter: data.nextPageInfo.pagefilter,
                }
                setnextPageInfo(updatedNextPageInfo);
                setIsLoading(false);
            }
        } catch (err) {
            console.log(err.name);
            setError(err.response.data);
            setIsLoading(false);
        }
    }
    
    const clearFilter = () => {
        setShowTaken(true);
        setShowMissed(true);
        setShowWrong(true);
        setShowLowPills(true);
        setShowNoPills(true);
        setShowOthers(true);
        setShowBox1(true);
        setShowBox2(true);
        setShowBox3(true);
        setShowBox4(true);
        setShowBox5(true);
        setShowBox6(true);
        setShowBox7(true);
        setShowBox8(true);
        setIsFilterModalOpen(false);
    }

    const handleFilter = () => {
        setShowTaken(filterTakenRef.current.checked);
        setShowMissed(filterMissedRef.current.checked);
        setShowWrong(filterWrongRef.current.checked);
        setShowLowPills(filterLowPillsRef.current.checked);
        setShowNoPills(filterNoPillsRef.current.checked);
        setShowOthers(filterOthersRef.current.checked);
        setShowBox1(filterBox1Ref.current.checked);
        setShowBox2(filterBox2Ref.current.checked);
        setShowBox3(filterBox3Ref.current.checked);
        setShowBox4(filterBox4Ref.current.checked);
        setShowBox5(filterBox5Ref.current.checked);
        setShowBox6(filterBox6Ref.current.checked);
        setShowBox7(filterBox7Ref.current.checked);
        setShowBox8(filterBox8Ref.current.checked);
        setIsFilterModalOpen(false);
    }

    const handleSort = () => {
        if(sortSelectRef.current.value === "Latest")
            setIsSortDesc(true);
        else
            setIsSortDesc(false);
        setIsSortModalOpen(false);
    }

    return(
        <div className="notifications">
            <h2>Notifications</h2>
            <div className="notifications-filter-menu">
                <div className="notifications-menu-filterbadges">
                </div>
                <div className="notifications-menu-buttons">
                    <button className="notifications-button" onClick={() => setIsFilterModalOpen(true)}>
                        <span className="material-icons-outlined">filter_alt</span>
                        Filter
                    </button>
                    <button className="notifications-button" onClick={() => setIsSortModalOpen(true)}>
                        <span className="material-icons-outlined">height</span>
                        Sort
                    </button>
                </div>
            </div>
            <hr/>
            <div className="notifications-table-content">
                {    
                    displayedNotifications.map( (notification, index) => 
                        <>
                            {
                                (index==0 || !areEqualDates([displayedNotifications[index-1].timestamp, notification.timestamp], deviceTimezone.timezoneValue)) &&
                                <div className="notification-group-date">{getNotificationDateString(notification.timestamp, deviceTimezone.timezoneValue)}</div>
                            }
                            <div key={notification.id} className="notification-item">
                                <div className="notification-item-time">{getTimeString(notification.timestamp, deviceTimezone.timezoneValue)}</div>
                                <div className="notification-item-message">{notification.message}</div>
                            </div>
                        </>
                    )
                }
                {
                    isLoading ?
                        <div className="spinner"></div>
                    :
                        nextPageInfo.token &&
                            <div className="notifications-show-more-wrapper">
                                <button className="notifications-show-more" onClick={getNotifications}><h4>Show More</h4></button>
                            </div>
                }
            </div>
            <Modal
                isOpen={isSortModalOpen}
                onRequestClose={ () => setIsSortModalOpen(false) }
                overlayClassName="modal-overlay"
                className="modal-content"
                closeTimeoutMS={200}
            >
                <div className="notifications-modal-content">
                    <h4>Sort By</h4>
                    <hr/>
                    <div className="sort-modal-body">
                        <label htmlFor="sort-time-select">Notification Time</label>
                        <select ref={sortSelectRef} id="sort-time-select" defaultValue={isSortDesc ? "Latest" : "Oldest"}>
                            <option value="Latest">Newest to Oldest</option>
                            <option value="Oldest">Oldest to Newest</option>
                        </select>
                    </div>
                    <hr/>
                    <div className="sort-modal-footer">
                        <button className="button-cancel" onClick={() => setIsSortModalOpen(false)}><h4>Cancel</h4></button>
                        <button className="button-apply" onClick={handleSort}><h4>Apply</h4></button>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={isFilterModalOpen}
                onRequestClose={ () => setIsFilterModalOpen(false) }
                overlayClassName="modal-overlay"
                className="modal-content"
                closeTimeoutMS={200}
            >
                <div className="notifications-modal-content">
                    <h4>Filter By</h4>
                    <hr/>
                    <div className="filter-modal-body">
                        <div className="filter-group">
                            <div className="filter-group-name">
                                <h5>Type</h5>
                            </div>
                            <div className="filter-group-filters">
                                <div className="filter-item">
                                    <input type="checkbox" ref={filterTakenRef} id="filter-pill-taken" defaultChecked={showTaken ? true : false} />
                                    <label htmlFor="filter-pill-taken">Pill Taken</label>
                                </div>
                                <div className="filter-item">
                                    <input type="checkbox" ref={filterMissedRef} id="filter-pill-missed" defaultChecked={showMissed ? true : false} />
                                    <label htmlFor="filter-pill-missed">Pill Missed</label>
                                </div>
                                <div className="filter-item">
                                    <input type="checkbox" ref={filterWrongRef} id="filter-pill-wrong" defaultChecked={showWrong ? true : false} />
                                    <label htmlFor="filter-pill-wrong">Wrong Pill</label>
                                </div>
                                <div className="filter-item">
                                    <input type="checkbox" ref={filterLowPillsRef} id="filter-low-pill" defaultChecked={showLowPills ? true : false} />
                                    <label htmlFor="filter-low-pill">Low Pills</label>
                                </div>
                                <div className="filter-item">
                                    <input type="checkbox" ref={filterNoPillsRef} id="filter-no-pill" defaultChecked={showNoPills ? true : false} />
                                    <label htmlFor="filter-no-pill">No Pills</label>
                                </div>
                                <div className="filter-item">
                                    <input type="checkbox" ref={filterOthersRef} id="filter-others" defaultChecked={showOthers ? true : false} />
                                    <label htmlFor="filter-others">Others</label>
                                </div>
                            </div>
                        </div>
                        <div className="filter-group">
                            <div className="filter-group-name">
                                <h5>Box</h5>
                            </div>
                            <div className="filter-group-filters">
                                <div className="filter-item">
                                    <input type="checkbox" ref={filterBox1Ref} id="filter-box1" defaultChecked={showBox1 ? true : false} />
                                    <label htmlFor="filter-box1">Box 1</label>
                                </div>
                                <div className="filter-item">
                                    <input type="checkbox" ref={filterBox2Ref} id="filter-box2" defaultChecked={showBox2 ? true : false} />
                                    <label htmlFor="filter-box2">Box 2</label>
                                </div>
                                <div className="filter-item">
                                    <input type="checkbox" ref={filterBox3Ref} id="filter-box3" defaultChecked={showBox3 ? true : false} />
                                    <label htmlFor="filter-box3">Box 3</label>
                                </div>
                                <div className="filter-item">
                                    <input type="checkbox" ref={filterBox4Ref} id="filter-box4" defaultChecked={showBox4 ? true : false} />
                                    <label htmlFor="filter-box4">Box 4</label>
                                </div>
                                <div className="filter-item">
                                    <input type="checkbox" ref={filterBox5Ref} id="filter-box5" defaultChecked={showBox5 ? true : false} />
                                    <label htmlFor="filter-box5">Box 5</label>
                                </div>
                                <div className="filter-item">
                                    <input type="checkbox" ref={filterBox6Ref} id="filter-box6" defaultChecked={showBox6 ? true : false} />
                                    <label htmlFor="filter-box6">Box 6</label>
                                </div>
                                <div className="filter-item">
                                    <input type="checkbox" ref={filterBox7Ref} id="filter-box7" defaultChecked={showBox7 ? true : false} />
                                    <label htmlFor="filter-box7">Box 7</label>
                                </div>
                                <div className="filter-item">
                                    <input type="checkbox" ref={filterBox8Ref} id="filter-box8" defaultChecked={showBox8 ? true : false} />
                                    <label htmlFor="filter-box8">Box 8</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="filter-modal-footer">
                        <div>
                            <button className="button-clear" onClick={clearFilter}><h4>Clear all filters</h4></button>
                        </div>
                        <div>
                            <button className="button-cancel" onClick={() => setIsFilterModalOpen(false)}><h4>Cancel</h4></button>
                            <button className="button-apply" onClick={handleFilter}><h4>Apply</h4></button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Notifications;